<template>
  <div class="ancestral-locations-section">
    <slot name="title">
      <h5>Where do the Chinese of {{ pinyin }} come from?</h5>
    </slot>
    <template v-if="isTemplate">
      <p v-prerender-if="descriptionPrerenderName">{{ description }}</p>
      <p v-prerender-if="notDescriptionPrerenderName">{{ defaultDescription }}</p>
    </template>
    <template v-else>
      <p v-if="description">{{ description }}</p>
      <p v-else>{{ defaultDescription }}</p>
    </template>
    <img
      :data-src="$getAsset('/assets/services/places/china-south.jpg')"
      class="lazyload"
      height="261"
      width="800"
      alt="China"
    />
    <loading-animated v-if="placeSearchLoadingState || !pinyin">
      <vcl-bullet-list
        :rows="1"
        width="300"
        slot-scope="props"
        :primary="props.primary"
        :secondary="props.secondary"
      ></vcl-bullet-list>
    </loading-animated>
    <span v-else-if="!count" class="supplemental"
      >No ancestral locations for {{ pinyin }} currently in our database.<br
    /></span>
    <router-link :to="toRoute" v-else rel="nofollow">View {{ count }} ancestral locations in China</router-link>
  </div>
</template>

<script>
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import {VclBulletList} from 'vue-content-loading';
import {mapGetters} from 'vuex';

export default {
  props: {
    pinyin: String,
    description: String,
    placeId: [String, Number],
    isTemplate: Boolean,
    descriptionPrerenderName: String,
  },
  computed: {
    ...mapGetters(['placeSearchMetaState', 'placeSearchLoadingState']),
    toRoute() {
      return {name: 'search-tool-village', query: {migration_place_id: this.placeId}};
    },
    count() {
      return this.placeSearchMetaState.total_count;
    },
    defaultDescription() {
      return `Most Chinese who settled in ${this.pinyin} come from the South of China.`;
    },
    notDescriptionPrerenderName() {
      return `not ${this.descriptionPrerenderName}`;
    },
  },

  components: {LoadingAnimated, VclBulletList},
};
</script>

<style scoped></style>
