<template>
  <div class="google-map">
    <div id="map"></div>
  </div>
</template>

<script>
import {loadGoogleMaps} from '@common/utils/script.loader';
import {LEVELS} from '@common/utils/utils.place';

export default {
  props: {
    name: String,
    address: String,
    coordinates: Object,
    level: [Number, String],
  },
  data() {
    return {
      map: null,
      google: null,
    };
  },
  mounted() {
    loadGoogleMaps().then(_google => {
      this.google = _google;
      this.showPlace();
    });
  },
  methods: {
    showPlace() {
      if (!this.google || !this.google.maps) {
        console.error(
          new Error(`No this.google when display a map; google=${!!this.google}; google.maps: ${!!this.google.maps}`)
        );
        return;
      }
      if (this.coordinates && this.coordinates.lat && this.coordinates.lng) {
        this.initMap();
        this.setMarker(this.coordinates);
        this.zoomByPlace();
      } else {
        const geoCoder = new this.google.maps.Geocoder();
        geoCoder.geocode({address: this.address}, result => {
          this.handleMap(result, geoCoder);
        });
      }
    },
    handleMap(result, geoCoder) {
      const callback = location => {
        if (!location) {
          return;
        }
        this.initMap();
        this.setMarker(location);
        this.zoomByPlace();
      };
      this.handleMapLocation(result, geoCoder, callback);
    },
    handleMapLocation(result, geoCoder, callback) {
      if (!result || !result.length) {
        return;
      }
      if (result.length === 1) {
        callback(result[0].geometry.location);
        return;
      }
      if (this.name === this.address) {
        return;
      }
      // when searching by full address, google may return many results
      // try to find by name in this case and display the exact location
      // in case the location by name search doesn't match the location by full address search,
      // display first match by full address
      geoCoder.geocode({address: this.name}, result2 => {
        result2 = result2 || [];
        for (let place1 of result) {
          for (let place2 of result2) {
            if (place1.place_id === place2.place_id) {
              callback(place1.geometry.location);
              return;
            }
          }
        }
        callback(result[0].geometry.location);
      });
    },
    initMap() {
      if (!this.map) {
        this.map = new this.google.maps.Map(document.getElementById('map'), {
          zoom: 6,
          mapTypeId: 'hybrid',
          animation: this.google.maps.Animation.DROP,
          disableDefaultUI: true,
          mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
        });
      }
    },
    setMarker(location) {
      new this.google.maps.marker.AdvancedMarkerElement({position: location, map: this.map});
      this.map.setCenter(location);
      this.$emit('set-marker', {location});
    },
    zoomByPlace() {
      this.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        setTimeout(() => {
          const zoomLevelMapping = {
            [LEVELS.country]: 5,
            [LEVELS.province]: 6,
            [LEVELS.city]: 8,
            [LEVELS.district]: 13,
            [LEVELS.town]: 13,
            [LEVELS.village]: 15,
            [LEVELS.sub_village]: 15,
          };
          const zoomLevel = zoomLevelMapping[this.level] || 13;

          this.map.setZoom(zoomLevel);
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.google-map {
  height: 500px;
  width: 100%;
  background-color: $background-alternate-color;

  @media only screen and (max-width: $breakpoint-tablet) {
    height: 300px;
  }

  #map {
    width: 100%;
    height: 100%;
  }
}
</style>
